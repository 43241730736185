import { Col, Row } from '@bootstrap-styled/v4'

import ContactForm from '../Block/ContactForm'
import ContactAbout from '../Block/ContactAbout'
import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll';

import Container from '../Common/Container'

import theme from '../../utils/constants'

const S = {}

S.SecondCol = styled(Col)`
  margin-top: 4rem;

  ${theme.breakpoints.lg} {
    margin-top: unset;
  }
`


const Contact = () => (
  <Container className="px-0">
    <Row className="mx-0">
      <Col lg="7">
        <ScrollAnimation animateIn="fadeInLeft" animateOnce>
          <ContactForm/>
        </ScrollAnimation>
      </Col>
      <S.SecondCol lg={{
        size: 4,
        offset: 1
      }} >
        <ScrollAnimation animateIn="fadeInRight" animateOnce>
          <ContactAbout/>
        </ScrollAnimation>
      </S.SecondCol>
    </Row>
  </Container>
)

export default Contact

