import { Button, Col, Row, Form, FormGroup, H2, Input, Label, P, Option, Select} from '@bootstrap-styled/v4'
import React, { Component } from 'react'

import { navigate } from 'gatsby-link'
import styled from 'styled-components'

import DatePicker from "react-datepicker"
import PhoneInput from 'react-phone-number-input'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import "react-datepicker/dist/react-datepicker.css"
import 'react-phone-number-input/style.css'
import 'react-google-places-autocomplete/dist/index.min.css';

import theme from '../../utils/constants'


const S = {}

S.Title = styled(H2)`
  margin-bottom: 1rem;
  color: ${theme.colors.darkGray};
`

S.Text = styled(P)`
  margin-bottom: 2rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: ${theme.colors.gray};
`

S.DatePickerCol = styled(Col)`
  & > .react-datepicker-wrapper {
    width: 100%;
  }

`

S.DatePicker = styled(DatePicker)`
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-radius: 0;
  border: 1px solid rgba(0,0,0,0.15);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s;
`

S.PhoneInput = styled(PhoneInput)`
  & > .PhoneInputCountry {
    margin-right: unset;
    border: 1px solid rgba(0,0,0,0.15);
    padding-left: var(--PhoneInputCountrySelect-marginRight);
    padding-right: var(--PhoneInputCountrySelect-marginRight);
  }

  & > input {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border-radius: 0;
    border: 1px solid rgba(0,0,0,0.15);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s;
  }
`

S.LocationCol = styled(Col)`
  & input {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border-radius: 0;
    border: 1px solid rgba(0,0,0,0.15);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s;
    box-shadow: unset;
  }

  & input:active, & input:hover, & input:focus {
    border: 1px solid rgba(0,0,0,0.15);
  }

  & input:focus {
    color: #464a4c;
    background-color: #fff;
    border-color: hsl(207.79999999999995,98.2%,53.4%);
    outline: none;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      date: new Date(),
      service: 'Pre Wedding'
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePhoneNumberChange = value => {
    this.setState({ 'contact': value })
  }

  handleLocationSelected = ({description}) => {
    this.setState({ 'location': description })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const response = { ...this.state }
    grecaptcha.ready(function() {
      grecaptcha.execute('6LcgV7YZAAAAAHEUyq7l5OXrOTtAZx7c9tT2ZrGm', {action: 'submit'}).then(function(token) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            'g-recaptcha-response': token,
            ...response,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch(error => alert(error))
      });
    });

  }


  render() {
    return (
      <>
        <S.Title>Get In Touch</S.Title>
        <S.Text>
          Please take a quick moment of your time to fill out the enquiry form and we will get back to you as soon as possible. We look forward to hearing from you.
        </S.Text>
        <Form
          name="contact"
          method="post"
          action="/contact/thanks"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          onSubmit={this.handleSubmit}
        >
          <Input hidden name="form-name" value="contact" />
          <Input hidden name="bot-field" onChange={this.handleChange} />
          <FormGroup row>
            <Col xs="12">
              <Label>Name</Label>
              <Input
                placeholder="Name"
                onChange={this.handleChange}
                name="name"
                id="name"
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label>Email</Label>
              <Input
                placeholder="Email"
                onChange={this.handleChange}
                name="email"
                id="email"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label>Contact Number/ Whatsapp</Label>
              <S.PhoneInput
                placeholder="Contact no."
                name="contact"
                id="contact"
                value={this.state.contact}
                onChange={this.handlePhoneNumberChange}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <S.DatePickerCol xs="12">
              <Label>Date</Label>
              <S.DatePicker
                selected={this.state.date}
                id="date"
                name="date"
                onChange={date => {
                this.setState({
                date: date
                })}}
              />
            </S.DatePickerCol>
          </FormGroup>
          <FormGroup row>
            <S.LocationCol xs="12">
              <Label>Location</Label>
              <GooglePlacesAutocomplete
                apiKey='AIzaSyBzC1ctlWQL7NBPKNcI8DFeTyMWW_qXWbw'
                placeholder='Location (Optional)'
                onSelect={this.handleLocationSelected}
                required={false}
              />
              <Input
                placeholder="location"
                name="location"
                id="location"
                hidden
                required={false}
              />
            </S.LocationCol>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label>Service</Label>
              <Input type="select" name="service" id="service" value={this.state.value} onChange={this.handleChange} required={true}>
                <Option value="Pre Wedding">Pre Wedding</Option>
                <Option value="Engagement Day">Engagement Day</Option>
                <Option value="Wedding Day">Wedding Day</Option>
                <Option value="Professional Profile">Professional Profile</Option>
                <Option value="Family Portrait">Family Portrait</Option>
                <Option value="Graduation">Graduation</Option>
                <Option value="Cosplay">Cosplay</Option>
                <Option value="Others">Others</Option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Label>Message</Label>
              <Input
                type="textarea"
                placeholder="Enter your message..."
                onChange={this.handleChange}
                name="message"
                id="message"
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs="12">
              <Button type="submit">Send Message</Button>
            </Col>
          </FormGroup>
        </Form>

      </>
    )
  }
}

export default ContactForm
