import Contact from '../../components/Section/Contact'
import Helmet from 'react-helmet'
import Layout from '../../components/Section/Layout'
import React from 'react'

import { graphql } from 'gatsby'
import ContactHero from '../../components/Block/Contacthero'

export default class ContactPage extends React.Component {
  constructor() {
    super()
  }

  render() {
    const data = this.props.data;

    return (
      <Layout>
        <Helmet>
          <title>Contact | Top KL Malaysia Photography Studio</title>
        </Helmet>
        <ContactHero image={data.contacthero} />
        <Contact />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    contacthero: file(relativePath: { eq: "contact-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 92) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
