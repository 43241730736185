import { Col, Row, H2, P} from '@bootstrap-styled/v4'
import React, { Component } from 'react'

import { navigate } from 'gatsby-link'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Link from '../Common/Link'

import theme from '../../utils/constants'

const S = {}

S.Title = styled(H2)`
  margin-bottom: 2rem;
  color: ${theme.colors.darkGray};
`

S.Text = styled(P)`
  letter-spacing: 1px;
  margin-bottom: 2rem;
  line-height: 2;
  color: ${theme.colors.gray};
`

S.TextLink = styled(Link)`
  text-decoration: underline;
`

S.BrandingRow = styled.div`
  && {
    display: none;
    padding-top: 1rem;

    ${theme.breakpoints.lg} {
      display: flex;
    }
  }
`

S.BrandingLink = styled(Link)`
  margin-right: 1rem;
  color: ${theme.colors.darkGray};

  &:hover {
    color: ${theme.colors.lightGray};
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactAbout extends Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <>
        <S.Title>Contact Us</S.Title>
        <S.Text>
          C-20-13A, Block Empire Soho 1<br />
          Empire Damansara<br />
          No.2 Jalan PJU 8/8A,<br />
          Damansara Perdana, PJU 8,<br />
          47820 Petaling Jaya,<br />
          Selangor, Malaysia
        </S.Text>

        <S.Text>
          Email: <S.TextLink href="mailto:contact@hourglassstudio.co">contact@hourglassstudio.co</S.TextLink><br />
          Contact: <S.TextLink href="tel:+6017-2158366">+60 17-215 8366</S.TextLink> (Tony)<br />
          Whatsapp: <S.TextLink href="https://wa.me/60163852137">+60 16-385 2137</S.TextLink> (Loo)
        </S.Text>
        <S.BrandingRow>
          <S.BrandingLink href="https://www.facebook.com/Hourglass-Studio-107659234110915" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookSquare} size = '2x'/>
          </S.BrandingLink>
          <S.BrandingLink href="https://www.instagram.com/hourglassstudio.co/?fbclid=IwAR1r7VcEPKKr9R_fiY33k5d3xp4yOCcyRPf8ftJ5yuOzUsF_enYC7fyv4hw" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size = '2x'/>
          </S.BrandingLink>
          <S.BrandingLink href="mailto:contact@hourglassstudio.co">
            <FontAwesomeIcon icon={faEnvelope} size = '2x'/>
          </S.BrandingLink>
        </S.BrandingRow>
      </>
    )
  }
}

export default ContactAbout
