import { H1 } from '@bootstrap-styled/v4'

import Link from '../Common/Link'
import HeroImg from '../Common/HeroImg'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'

const S = {}

S.Title = styled(H1)`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.sans.normal};
  text-align: center;
  font-size: ${theme.fontSizes[5]}px;
  line-height: 1.5;
  letter-spacing: 0.25rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  padding-left: 1rem;
  padding-right: 1rem;
  && {
  }

  ${theme.breakpoints.lg} {
    letter-spacing: 0.5rem;
    width: 90vw;
  }

  ${theme.breakpoints.xxl} {
    font-size: ${theme.fontSizes[5]}px;
    letter-spacing: 0.75rem;
    margin-bottom: 1.5rem;
  }
`

const Contacthero = class extends React.Component {
  render() {
    const props = this.props;
    return (
      <HeroImg image={props.image}>
        <S.Title>Contact Us</S.Title>
      </HeroImg>
    )
  }
}

export default Contacthero
